@import 'styles/variables.scss';

.partnerShip {
  background: $color-black-1;
  padding: 80px 0px 64px;

  &Heading {
    text-align: center;
    h2 {
      color: $color-grey-4;
      @include font-medium($font-size: 2rem);
      line-height: 150%;
      margin-bottom: 12px;
    }

    h4 {
      color: $color-grey-6;
      @include font-bold($font-size: 4rem);
      line-height: 124%;
      padding-bottom: 64px;
    }
  }

  &University {
    @include d-flex($jc: normal);
    gap: 64px;
    align-items: center;
    width: 100%;
    padding: 80px 0px 0px;

    &:nth-child(3) {
      flex-direction: row-reverse;
      padding-left: 64px;

      .partnerShipContent {
        padding-right: 0px;
      }
    }

    h2 {
      @include font-medium($font-size: 3rem);
      color: $color-grey-6;
      margin-bottom: 24px;
    }

    p {
      @include font-regular($font-size: 2rem);
      line-height: 150%;
      margin-bottom: 24px;
      color: $color-grey-14;
      text-align: justify;
    }

    ul {
      padding-left: 22px;
      li {
        @include font-regular($font-size: 2rem);
        line-height: 150%;
        color: $color-grey-14;
      }
    }
  }

  &Image {
    flex-basis: 75.8%;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &Content {
    flex-basis: 100%;
    padding-right: 64px;
  }

  &Data {
    padding-right: 0px;
  }
}

// responsive
@media (max-width: 992px) {
  .partnerShip {
    padding: 40px 16px;

    &Heading {
      padding: 24px 0px 0px 0px;
      h2 {
        @include font-medium($font-size: 1.4rem);
        line-height: 124%;
      }
      h4 {
        @include font-bold($font-size: 2.4rem);
        padding-bottom: 24px;
      }
    }

    &University {
      flex-wrap: wrap;
      gap: 16px;
      padding: 24px 0px;

      &:nth-child(3) {
        flex-direction: unset;
        padding: 24px 0px;
      }

      h2 {
        @include font-medium($font-size: 2.4rem);
        margin-bottom: 12px;
      }

      p {
        @include font-regular($font-size: 1.6rem);
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      ul {
        li {
          @include font-regular($font-size: 1.6rem);
        }
      }
    }
    &Image {
      flex-basis: auto;
    }
    &Content {
      padding-right: 0px;
    }
  }
}
