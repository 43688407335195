@import 'styles/variables.scss';

.interestedPartner {
  position: relative;
  background-color: $color-black-1;
  background: url('../../assets/images/home/bgVideo.png');
  // padding: 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
  @include d-flex();

  &Heading {
    // height: 100%;
    @include d-flex($jc: center);
    flex-direction: column;

    h2 {
      @include font-bold($font-size: 4.8rem);
      color: $color-white-2;
      line-height: 124%;
      text-align: center;
      max-width: 600px;
      margin-bottom: 16px;
      background: linear-gradient(
        180deg,
        #f7f7f7 -27.5%,
        #f7f7f7 69.36%,
        rgba(247, 247, 247, 0.66) 92.78%,
        rgba(247, 247, 247, 0) 124.17%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    span {
      color: $color-grey-8;
      @include font-regular(2rem);
      line-height: 150%;
      margin-bottom: 36px;
    }

    // button {
    //   border-radius: 8px;
    //   border: 4px solid rgba(255, 255, 255, 0.25);
    //   background-color: #2cd9b8;
    //   box-shadow: 0px 14px 20px 0px rgba(255, 255, 255, 0.07);
    //   margin: 0 auto;
    //   display: flex;
    //   color: $color-black-1;
    //   @include font-medium($font-size: 1.6rem);
    //   line-height: 124%;
    //   padding: 14px 26px;
    //   cursor: pointer;
    //   transition: all 0.2s ease;

    //   &:hover {
    //     background: #fff;
    //     color: $color-black-1;
    //     box-shadow: 0 0 0 1px inset $color-blue-2;
    //   }
    // }
  }
  .aroundImages {
    div {
      position: absolute;
      &:first-child {
        left: 24.5%;
        top: 15%;
        img {
          max-width: 130px;
          height: 130px;
        }
      }
      &:nth-child(2) {
        left: 8%;
        top: 43%;
        img {
          max-width: 101px;
          height: 101px;
        }
      }
      &:nth-child(3) {
        left: 19%;
        top: 73%;
        img {
          max-width: 166px;
          height: 166px;
        }
      }
      &:nth-child(4) {
        right: 32%;
        top: 12%;
        img {
          max-width: 117px;
          height: 117px;
        }
      }
      &:nth-child(5) {
        right: 8%;
        top: 27%;
        img {
          max-width: 202px;
          height: 202px;
        }
      }
      &:nth-child(6) {
        // max-width: 152px;
        right: 22%;
        top: 75%;
        img {
          max-width: 152px;
          height: 152px;
        }
      }
    }
  }
  .animate__fadeInRight {
    --animate-duration: 2s;
  }
  .animate__fadeInLeft {
    --animate-duration: 2s;
  }
  .animate__fadeOutRight {
    --animate-duration: 2s;
  }
  .animate__fadeOutLeft {
    --animate-duration: 2s;
  }
  .animate__fadeInUp {
    --animate-duration: 2s;
  }
  .animate__fadeOutDown {
    --animate-duration: 2s;
  }
}

// media query
@media (max-width: 768px) {
  .interestedPartner {
    min-height: 400px;

    &Heading {
      gap: 0;
      padding: 0;

      h2 {
        font-size: 24px;
        max-width: 328px;
      }

      span {
        text-align: center;
      }
      // button {
      //   padding: 8px 16px;
      //   font-size: 14px;
      //   border-radius: 4px;
      // }
    }
  }
}
