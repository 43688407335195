@import 'styles/variables.scss';

.concepts {
  // background: $color-black-1;
  background: $color-white-0;
  position: relative;

  &Title {
    text-align: center;
    margin-bottom: 64px;

    h2 {
      @include font-medium($font-size: 2rem);
      color: $color-black-4;
      line-height: 150%;
      text-transform: uppercase;
      margin-bottom: 16px;
      @include d-flex(center);
      gap: 8px;
    }

    h4 {
      color: $color-black-1;
      @include font-bold($font-size: 3.8rem);
      line-height: 124%;
    }
  }

  &Practice {
    @include d-flex(center);
    gap: 32px;
    flex-wrap: wrap;
    padding-top: 32px;

    &Icon {
      margin-bottom: 32px;

      img {
        width: 66px;
        height: 66px;
      }
    }

    &Gradient {
      position: relative;
      align-self: stretch;

      // &:hover {
      //   background: $linear-gradient-0;
      // }
    }

    &Measure {
      max-width: 378px;
      // border: 1px solid $color-green-3;
      // padding: 66px 24px 24px;
      padding: 24px;
      // background: $color-black-1;
      position: relative;
      height: 100%;
      border-radius: 10px;
      background: $color-blue-5;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        background: url(../../assets/images/measure.svg);
        background-size: 77%;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        background-position: right top;
      }
    }

    &Report {
      &::after {
        background: url(../../assets/images/report.svg);
        background-size: 55%;
        background-repeat: no-repeat;
        background-position: right top;
      }
    }

    &Verify {
      &::after {
        background: url(../../assets/images/verify.svg);
        background-size: 42%;
        background-repeat: no-repeat;
        background-position: right top;
      }
    }

    h4 {
      // color: $color-grey-7;
      color: $color-black-1;
      @include font-bold($font-size: 2.8rem);
      line-height: normal;
      position: relative;
      margin-bottom: 20px;

      // &::before {
      //   content: "";
      //   height: 28px;
      //   width: 1px;
      //   background: $color-grey-0;
      //   position: absolute;
      //   left: -25px;
      // }
    }

    p {
      // color: $color-grey-8;
      color: $color-black-4;
      line-height: 150%;
      @include font-regular($font-size: 1.8rem);
      max-width: 334px;
    }
  }
}

// responsive

@media (max-width: 768px) {
  .concepts {
    padding: 40px 16px;

    &Title {
      margin-bottom: 32px;
      h2 {
        font-size: 1.4rem;
        line-height: 124%;
      }
      h4 {
        font-size: 2.4rem;
      }
    }
    &Practice {
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;
      padding: 0;

      &Measure {
        padding: 16px 18px;
        max-width: 100%;

        &::after {
          background-size: inherit;
          background-position: top right;
        }
      }

      &Icon {
        margin-bottom: 16px;

        img {
          width: 50px;
          height: 50px;
        }
      }

      h4 {
        font-size: 2rem;
        margin-bottom: 8px;

        &::before {
          left: -19px;
        }
      }

      p {
        font-size: 1.6rem;
      }
    }
  }
}
