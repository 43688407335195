@import 'styles/variables.scss';

.enquirySent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 998;

  &Container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 11px;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &Content {
    display: flex;
    padding: 32px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background: $color-white-0;
    position: relative;
    border-radius: 10px;

    &success {
      width: 44px;
      height: 44px;
    }

    &close {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      position: absolute;
      right: -18px;
      top: -15px;
      cursor: pointer;
    }

    &title {
      font-size: 2.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: $color-green-0;
    }

    &subtitle {
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: $color-green-3;
      max-width: 249px;
      text-align: center;
    }
  }

  // responisve
  @media (max-width: 576px) {
    &Container {
      width: 80%;
    }

    &Content {
      padding: 22px 15px;
      gap: 10px;
      border-radius: 8px;

      &title {
        font-size: 1.9rem;
      }

      &subtitle {
        font-size: 1.5rem;
        max-width: 100%;
      }
    }
  }
}
