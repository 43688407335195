@import 'styles/variables.scss';

.footer {
  background: $color-black-1;
  padding: 0px 16px;
  position: relative;
  z-index: 5;

  &Location {
    display: flex;
    justify-content: center;
    gap: 8px;
    padding: 32px 0px;
    align-items: center;
    border-bottom: 0.5px solid $color-green-0;

    p {
      @include font-medium($font-size: 1.6rem);
      line-height: 150%;
      color: $color-grey-8;
    }
  }

  &Container {
    @include d-flex($jc: flex-start);
    gap: 22%;
    padding: 28px 0px;

    h5 {
      @include font-medium($font-size: 1.4rem);
      color: $color-grey-0;
      line-height: 124%;
    }
  }

  &List {
    @include d-flex($jc: space-between);
    gap: 32px;

    li {
      @include font-medium($font-size: 1.4rem);
      color: $color-grey-0;
      line-height: 124%;
      // cursor: pointer;

      &:hover {
        // color: $color-grey-11;
        // text-decoration: underline;

        &::marker {
          color: $color-grey-0;
        }
      }
    }
    & li:first-child {
      list-style-type: none;
    }
    & li:nth-child(2) {
      padding-left: 16px;
    }
  }

  &Links {
    @include d-flex($jc: normal);
    gap: 16px;

    svg {
      cursor: pointer;

      &:hover {
        path {
          fill: $color-grey-11;
        }
      }
    }
  }
}

// responsive
@media (max-width: 992px) {
  .footer {
    &Container {
      justify-content: space-evenly;
      gap: 12px;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    &Location {
      padding: 24px 0px;

      p {
        @include font-regular($font-size: 1.4rem);
      }
    }

    &Container {
      flex-wrap: wrap;
      justify-content: center;
      padding: 24px 0px;
    }

    &List {
      flex-wrap: wrap;
      justify-content: center;
      gap: 12px 32px;
    }
  }
}

@media (max-width: 409px) {
  .footer {
    p {
      max-width: 254px;
      text-align: center;
    }
    &Container {
      h5 {
        margin-bottom: 12px;
      }
    }
    &List {
      gap: 12px 32px;
      margin-bottom: 12px;

      li:nth-child(3) {
        list-style-type: none;
      }
    }
  }
}
