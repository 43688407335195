@import 'styles/variables.scss';

.product {
  width: 100%;
  // margin-top: 76px;
}
.products {
  background-color: $color-black-1;
  padding-top: 76px;
}
@media screen and (max-width: 770px) {
  .product {
    // margin-top: 66px;
  }

  .products {
    padding-top: 66px;
  }
}
