@import '/src/styles/variables.scss';

.ourTech {
  .commonHeading {
    margin: 0 !important;
    h1 {
      @include d-flex(flex-start !important);
    }
  }

  &Header {
    @include d-flex(space-between);
    margin-bottom: 48px;

    h3 {
      color: $color-black-1;
      @include font-bold(3.8rem);
      line-height: 52px;
      max-width: 47%;
    }
  }

  &Cards {
    @include d-flex();
    align-items: stretch;
    gap: 32px;
  }

  &Video {
    max-width: 589px;
    width: 100%;
    position: relative;
    @include d-flex();
    align-items: stretch;
    height: 402px;
    transition: all 0.7s ease;
    background: white;
    animation: fadeIn 4s ease-in-out forwards infinite;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &Process {
    flex-basis: 50%;
    @include d-flex(space-between);
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    &Contents {
      border-radius: 10px;
      border: 1px solid #d8d8d8;
      width: 100%;
      padding: 24px;
      cursor: pointer;
      position: relative;
      overflow-y: hidden;
      transition: all 0.5s ease;

      h3 {
        color: $color-black-1;
        @include font-bold(2.4rem);
      }

      p {
        color: #626b70;
        @include font-regular(1.8rem);
        line-height: 150%;
        margin-top: 15px;
        min-height: 81px;
      }
    }
  }
}

@keyframes data {
  0% {
    height: 0%;
  }

  100% {
    height: calc(100% - 48px);
  }
}

.stepActive {
  border-radius: 10px;
  background: var(--Accent-Color-Accent-4, #c8d9eb);
  position: relative;
  overflow: hidden;

  .ourTechTitle {
    margin: 0 0 0 20px;
  }

  &::after {
    content: '';
    width: 3px;
    height: 0%;
    background: #4c8feb;
    position: absolute;
    top: 24px;
    margin: auto 0;
    display: block;
    opacity: 1;
    animation: data 4s linear forwards;
  }

  &::before {
    content: '';
    width: 3px;
    height: calc(100% - 48px);
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    display: block;
    opacity: 1;
  }
}

//   cards
.posterData {
  max-width: 589px;
  width: 100%;
  transition: all 0.5s ease;
  border-radius: 10px;
  min-height: 100%;
  object-fit: fill;
  transition: opacity 0.5s ease-in-out;
}

@media (max-width: 992px) {
  .ourTech {
    .commonHeading {
      margin: 0 0 32px !important;

      h1 {
        @include d-flex(center !important);
      }
    }

    &Process {
      flex-basis: 100%;

      &Contents {
        padding: 18px;
      }

      h3 {
        @include font-bold(1.6rem);
        margin-bottom: 13px;
      }
    }
  }
}
