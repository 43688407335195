@import 'styles/variables.scss';

.productHero {
  width: 100%;
  background-color: $color-black-1;
  position: relative;
  height: calc(100vh - 76px);
  position: relative;
  overflow: hidden;

  @include d-flex($jc: center);
  flex-direction: column;

  &Content {
    color: $color-grey-6;
    text-align: center;
    font-style: normal;
    @include d-flex($jc: center);
    flex-direction: column;
    gap: 28px;
    max-width: 55%;

    h2 {
      font-size: 48px;
      font-weight: 700;
      line-height: 124%;
      z-index: 2;
    }
    p {
      font-size: 24px;
      font-weight: 400;
      line-height: 150%;
      z-index: 2;
      color: $color-grey-1;
      max-width: 77%;
    }

    // button {
    //   background-color: $color-blue-2;
    //   color: $color-black-1;
    //   font-size: 16px;
    //   line-height: 124%;
    //   padding: 12px 24px;
    //   margin-top: 4px;
    //   border: none;
    //   z-index: 2;
    //   cursor: pointer;
    //   border-radius: 6px;
    //   transition: all 0.2s ease;
    //   &:hover {
    //     background: $color-black-1;
    //     color: $color-grey-11;
    //     box-shadow: 0 0 0 1px inset $color-blue-2;
    //   }
    // }
  }

  &Back {
    position: absolute;
    inset: 0;

    &::after {
      content: '';
      padding: 20px;
      position: absolute;
      width: 100%;
      height: 100%;
      background: $linear-gradient-5;
      left: 0;
      top: 0;
    }
  }

  .hero {
    &Circle {
      position: absolute;
      top: 50%;
      left: 50%;
      aspect-ratio: 1;
      border-radius: 50%;
      border: 1px solid #5e6265;
      transform: translate(-50%, -50%) rotate(0deg);

      transform-style: preserve-3d;
      transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &Icon {
      @include d-flex($jc: center);
      z-index: 4;
      position: absolute;

      aspect-ratio: 1;
      border-radius: 50%;
      pointer-events: all;
      background-color: $color-blue-3;
      width: 80px;
      height: 80px;
      img {
        z-index: 2;
        position: relative;
      }
    }

    .circle-1 {
      width: 60vw;

      .heroIcon-1 {
        top: 70%;
        left: 2%;
      }

      .heroIcon-2 {
        top: 20%;
        left: unset;
        right: 2%;
      }
    }

    .circle-2 {
      width: 74vw;

      .heroIcon-1 {
        top: 20%;
        left: 2%;
      }

      .heroIcon-2 {
        top: 70%;
        left: unset;
        right: 2%;
      }
    }

    .circle-3 {
      width: 90vw;

      .heroIcon-1 {
        top: 48%;
        left: -3%;
      }

      .heroIcon-2 {
        top: 48%;
        left: unset;
        right: -3%;
      }
    }

    .circle-4 {
      width: 106vw;
    }

    .hero-red {
      background-color: $color-red-0;
    }
    .hero-yellow {
      background-color: $color-yellow-0;
    }
    .hero-green {
      background-color: $color-grey-5;
    }
  }
}

@media screen and (max-width: 800px) {
  .productHero {
    padding: 0 16px;
    height: calc(100vh - 120px);
    &Content {
      gap: 20px;
      max-width: 90%;
      h2 {
        font-size: 40px;
        max-width: 100%;
      }
      p {
        font-size: 16px;
        max-width: 100%;
      }

      button {
        font-size: 16px;
        border-radius: 4px;
        padding: 8px 16px;
      }
    }

    &Back {
      &::after {
        background: $linear-gradient-6;
        z-index: 1;
      }
    }

    .hero {
      .heroIcon {
        display: none;
      }
      .circle-1 {
        width: 42vh;
      }
      .circle-2 {
        width: 56vh;
      }
      .circle-3 {
        width: 70vh;
      }

      .circle-4 {
        width: 84vh;
      }
    }
  }
}
