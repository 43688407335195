@import 'styles/variables.scss';

.carbon {
  background: #f1f7f6;

  .commonHeading {
    p {
      max-width: 520px !important;
      margin: 0 auto;
    }
  }

  &LifeCycle {
    position: relative;
    background: url('../../assets/images/home/Lines.png');
    background-repeat: no-repeat;
    background-size: initial;
    max-height: 720px;
    background-position: 50% 32%;
    height: 580px;

    &Logo {
      @include d-flex(center);
      flex-direction: column;
      gap: 20px;
      border-radius: 8px;
      border: 3px solid #2cd9b8;
      background: #f7fefc;
      box-shadow: 0px 20px 52px -13px rgba(26, 131, 111, 0.26);
      max-width: 206px;
      margin: 0 auto;
      padding: 32px;
      position: absolute;
      margin: auto;
      max-height: 176px;
      top: -23%;
      bottom: 0;
      right: 0;
      left: 0;
    }

    &Card {
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 20px 36px -16px rgba(0, 167, 134, 0.08);
      padding: 18px 20px;

      &:nth-child(2) {
        position: absolute;
        top: 0;
        min-height: 190px;
        max-width: 306px;
        width: 100%;
      }

      &:nth-child(3) {
        position: absolute;
        top: 0;
        right: 0;
        min-height: 194px;
        max-width: 306px;
        width: 100%;
      }

      &:nth-child(4) {
        position: absolute;
        bottom: 0;
        width: 100%;
        min-height: 298px;
        max-width: 368px;
        width: 100%;
      }

      &:nth-child(5) {
        position: absolute;
        bottom: 0;
        right: 0;
        min-height: 294px;
        max-width: 368px;
        width: 100%;
      }

      &Buy {
        @include d-flex();
        gap: 14px;
        flex-direction: column;
        align-items: flex-start;

        .logo {
          @include d-flex();
          gap: 10px;

          span {
            color: $color-black-1;
            @include font-bold(1.6rem);
            letter-spacing: -0.24px;
          }
        }
      }

      ul {
        list-style-type: none;

        li {
          list-style-type: none;
          color: $color-green-0;
          @include font-regular(1.5rem);
          line-height: 26px;
          position: relative;

          &::before {
            content: '';
            width: 4px;
            height: 4px;
            position: absolute;
            background: #0a3830;
            border-radius: 50%;
            top: 11px;
            left: -15px;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
  }
}

.carbonResponsive {
  @include d-flex();
  flex-direction: column;
  gap: 22px;
}

.carbonLifeCycleMobile {
  @include d-flex();
  gap: 14px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 20px 36px -16px rgba(0, 167, 134, 0.08);
  padding: 18px;
  width: 100%;

  .logo {
    @include d-flex();
    gap: 10px;

    span {
      color: $color-black-1;
      @include font-bold(1.6rem);
      letter-spacing: -0.24px;
    }
  }

  ul {
    li {
      color: $color-green-0;
      @include font-regular(1.5rem);
      line-height: 26px;
    }
  }
}
