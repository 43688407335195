@import 'styles/variables.scss';

.aboutUs {
  background-color: $color-black-1;
  padding-top: 76px;
}

@media (max-width: 800px) {
  .aboutUs {
    background-color: $color-black-1;
    padding-top: 66px;
  }
}
