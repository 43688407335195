@import 'styles/variables.scss';

.insightCard {
  display: flex;
  flex-direction: column;
  //flex-grow: 1;
  flex-basis: 32%;
  max-width: 100%;
  width: 100%;
  border-radius: 18px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid $color-grey-12;

  &Img {
    width: 100%;
    overflow: hidden;
    display: flex;

    img {
      aspect-ratio: 1.04/1;
      width: 100%;
      object-fit: cover;
      height: 100%;
      transition: all 0.5s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &Content {
    width: 98%;
    padding: 24px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;

    span {
      display: flex;
      padding: 6px 8px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 4px;
      background-color: $color-grey-6;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 124%;
      color: $color-black-1;
    }

    p {
      color: $color-green-0;
      font-feature-settings:
        'clig' off,
        'liga' off;

      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin: 12px 0 22px;
      flex: 0 0 90px;
      text-align: left;
    }

    button {
      display: flex;
      padding: 12px 24px;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      line-height: 124%;
      background: $color-blue-2;
      border: none;
      font-size: 16px;
      font-weight: 500;
      color: $color-black-1;
      font-family: 'Satoshi', sans-serif;
      cursor: pointer;
      white-space: nowrap;
      position: relative;
      // z-index: 222;
      transition: all 0.2s ease;
      &:hover {
        background: transparent;
        color: $color-black-1;
        box-shadow: 0 0 0 1px inset $color-blue-2;
      }

      // &:hover {
      //   background: transparent;
      //   outline: 1px solid $color-blue-2;
      // }
    }
  }
}

@media screen and (max-width: 800px) {
  .insightCard {
    border-radius: 4px;

    &Img:hover {
      scale: 1;
    }

    &Content {
      max-width: 100%;
      padding: 16px;
      gap: 0px;
      span {
        font-size: 12px;
      }

      p {
        font-size: 16px;
        flex: 0;
        margin: 8px 0px 16px 0px;
        max-width: 286px;
      }

      button {
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 4px;

        img {
          width: 17px;
          height: 17px;
        }
      }
    }
  }
}
