@import 'styles/variables.scss';

.insights {
  width: 100%;
  margin: 0 auto;
  background-color: $color-white-0;
  // margin-top: 76px;
}

.insightHeader {
  padding-top: 76px;
  background-color: $color-black-1;
}

@media (max-width: 800px) {
  .insightHeader {
    padding-top: 67px;
  }
}
