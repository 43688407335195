@import 'styles/variables.scss';

.ourTeam {
  background: $color-black-1;

  h3 {
    color: $color-grey-4;
    text-align: center;
    @include font-medium(2rem);
    line-height: 150%;
    margin-bottom: 10px;
  }

  p {
    color: $color-grey-6;
    text-align: center;
    @include font-bold(4rem);
    line-height: 124%;
  }

  &Container {
    max-width: 972px !important;
  }

  &Managers {
    @include d-flex(flex-start);
    margin-top: 64px;
    gap: 99px;
    row-gap: 64px;
    align-items: inherit;
    flex-wrap: wrap;
  }

  &Manager {
    text-align: center;
    cursor: pointer;

    img {
      max-width: 258px;
      transition: filter 0.3s ease;

      &:hover {
        filter: grayscale(100%);
        background-color: #829c96;
        border-radius: 50%;
      }
    }
    &:hover {
      h4 {
        text-decoration: underline;
        text-underline-offset: 6px;
      }
    }

    h4 {
      margin: 40px 0 4px;
      color: $color-grey-6;
      @include font-medium(2.4rem);
      line-height: 150%;
    }

    p {
      color: $color-grey-0;
      @include font-medium(1.6rem);
      line-height: 124%;
    }
  }

  &PopupWindow {
    width: 100%;
    transition: all 0.8s ease;
    height: 100vh;
    top: 0;
    position: fixed;
    background: $color-black-1;
  }

  &Popup {
    border-radius: 12px;
    background: #fff;
    width: 66.7%;
    padding: 60px 40px;
    position: relative;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.8s ease;
    z-index: 9;

    &Img {
      display: flex;
      margin-left: auto;
      position: absolute;
      right: -32px;
      top: -28px;
      transition: all 0.8s ease;
      cursor: pointer;
    }

    &Content {
      @include d-flex(space-between);
      gap: 54px;
      align-items: inherit;

      img {
        min-height: 320px;
      }
    }

    &Data {
      h4 {
        color: $color-black-1;
        @include font-medium(2.4rem);
        line-height: 150%;
        margin-bottom: 4px;
      }

      p {
        color: $color-green-1;
        @include font-medium(2.4rem);
        text-align: inherit;
        line-height: 124%;
        margin-bottom: 40px;
      }

      ul {
        li {
          color: $color-black-1;
          @include font-regular(2rem);
          line-height: 40px;
        }
      }
    }
  }
}

.hidden {
  transform: translateY(-105%) !important;
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 992px) {
  .ourTeam {
    h3 {
      @include font-medium(1.6rem);
      margin-bottom: 8px;
    }

    p {
      @include font-bold(3.5rem);
      line-height: 122%;
    }

    &Managers {
      @include d-flex(center);
      margin-top: 44px;
      gap: 79px;
      row-gap: 44px;
    }

    &Manager {
      img {
        max-width: 100%;
      }

      h4 {
        margin: 30px 0 4px;
        @include font-medium(2rem);
      }

      p {
        @include font-medium(1.5rem);
      }
    }

    &Popup {
      width: 90%;
      padding: 36px 20px;
      top: 40%;
      left: 40%;
      transform: translate(-40%, -40%);

      &Content {
        flex-direction: column;
        gap: 30px;

        img {
          min-height: 0;
          max-width: 280px;
          margin: 0 auto;
        }
      }

      &Data {
        h4 {
          @include font-medium(2rem);
        }

        p {
          @include font-medium(2rem);
          margin-bottom: 20px;
        }

        ul {
          li {
            color: $color-black-1;
            @include font-regular(1.6rem);
            line-height: 25px;
          }
        }
      }
    }
  }
}
