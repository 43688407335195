@import 'styles/variables.scss';

.productContent {
  background: $color-white-0;

  &Earth {
    @include d-flex($jc: normal);
    align-items: center;
    width: 100%;
  }

  &Data {
    flex-basis: 81%;

    h2 {
      @include font-medium($font-size: 3rem);
      line-height: 150%;
      margin-bottom: 24px;
      max-width: 590px;
      color: $color-black-2;
    }
    p {
      @include font-regular($font-size: 2rem);
      line-height: 150%;
      max-width: 625px;
      color: $color-grey-3;
    }
  }

  &Image {
    flex-basis: 55.2%;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 992px) {
  .productContent {
    padding: 40px 16px;

    &Earth {
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column-reverse;
      gap: 16px;
    }

    &Data {
      h2 {
        font-size: 2.4rem;
        margin-bottom: 8px;
      }
      p {
        font-size: 1.6rem;
      }
    }

    &Image {
      flex-basis: auto;
    }
  }
}
