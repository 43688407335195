@import 'styles/variables.scss';

.insight {
  background: #f1f7f6;

  &Navigation {
    @include d-flex();
    gap: 24px;
    flex-wrap: wrap;
    padding-top: 32px;

    &Data {
      position: absolute;
      bottom: 22px;
      right: 14px;
      left: 22px;
    }
  }

  &Image {
    position: relative;
    @include d-flex();
    align-items: stretch;
    cursor: pointer;
    overflow: hidden;
    // width: 100%;
    border-radius: 10px;

    img {
      border-radius: 10px;
      transition: all 0.5s ease;
      object-fit: cover;

      &:hover {
        transform: scale(1.1);
      }
      // width: 100%;
    }
  }

  &Research {
    span {
      color: $color-blue-6;
      @include font-medium(1.4rem);
      line-height: 124%;
      border-radius: 4px;
      border: 0.5px solid #fff;
      border-right: none;
      border-bottom: none;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(2px);
      padding: 6px 8px;
    }
  }

  &Contents {
    margin-top: 20px;
    @include d-flex(space-between);
    align-items: flex-end;

    img {
      // position: absolute;
      // right: 0;
      // bottom: 0;
    }

    p {
      color: #fff;
      @include font-medium(2.2rem);
      line-height: 124%;
      max-width: 473px;
    }
  }
}

.organic {
  p {
    max-width: 205px !important;
  }
}

.nature {
  p {
    max-width: 246px !important;
  }
}

@media (max-width: 992px) {
  .insight {
    &Navigation {
      padding: 0;
    }

    &Contents {
      p {
        @include font-medium(1.6rem);
        max-width: 100%;
      }
    }

    &Image {
      width: 100%;
      &Bg {
        width: 100%;
      }
    }

    &Research {
      span {
        border: none;
      }
    }
  }

  .organic {
    p {
      max-width: 100% !important;
    }
  }

  .nature {
    p {
      max-width: 100% !important;
    }
  }
}
