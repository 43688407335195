$color-white-0: #ffffff;
$color-white-1: #fffffe;
$color-white-2: #f7f7f7;

$color-blue-0: #f1f7f6;
$color-blue-1: #2fd6b6;
$color-blue-2: #2cd9b8;
$color-blue-3: #c8d9eb;
$color-blue-4: #e9f7f5;
$color-blue-5: #e5f6f3;
$color-blue-6: #7de8d3;

$color-black-0: #000000;
$color-black-1: #051a16;
$color-black-2: #21272a;
$color-black-3: #313131;
$color-black-4: #6b6b6b;
$color-black-5: #101828;

$color-grey-0: #829c96;
$color-grey-1: #dddfdf;
$color-grey-2: #d8e0ed;
$color-grey-3: #626b70;
$color-grey-4: #dcf9f3;
$color-grey-5: #d3e4cd;
$color-grey-6: #e8e9e9;
$color-grey-7: #dde4e2;
$color-grey-8: #a4b7b3;
$color-grey-11: #f6fdfc;
$color-grey-12: #d0d2d2;
$color-grey-13: #a4b7b3;
$color-grey-14: #a1a5a5;
$color-grey-15: #4d4d4d;
$color-grey-16: #667085;
$color-grey-17: #eff0f1;
$color-grey-18: #bbb;
$color-grey-19: #a7a7a7;

$color-green-0: #0a3830;
$color-green-1: #14705f;
$color-green-2: #f1f7f6;
$color-green-3: #105649;
$color-green-4: #07221d;
$color-green-5: #0d443a;
$color-green-6: #07271e;
$color-green-8: #142522;

$color-red-0: #ffd1d1;

$color-yellow-0: #f6eabe;

$linear-gradient-0: linear-gradient(115.96deg, #2cd9b8 0.99%, #b6f2e6 99.94%);
$linear-gradient-1: linear-gradient(
  270deg,
  #d3e4cd 0%,
  rgba(211, 228, 205, 0) 69.27%
);

$linear-gradient-2: linear-gradient(
  274deg,
  #d3e4cd 0%,
  rgba(211, 228, 205, 0) 49.29%,
  #d3e4cd 100%
);
$linear-gradient-3: linear-gradient(
  88deg,
  #d3e4cd 0%,
  rgba(211, 228, 205, 0) 69.27%
);
$linear-gradient-4: linear-gradient(
  128deg,
  #d3e4cd 0%,
  rgba(211, 228, 205, 0) 69.27%
);

$linear-gradient-5: linear-gradient(
  0deg,
  rgba(5, 26, 22, 1) 0%,
  rgba(255, 255, 255, 0) 20%,
  rgba(255, 255, 255, 0) 100%
);

$linear-gradient-6: linear-gradient(
  90deg,
  rgba(5, 26, 22, 1) 0%,
  rgba(255, 255, 255, 0) 50%,
  rgba(5, 26, 22, 1) 100%
);

$linear-gradient-7: linear-gradient(180deg, #2cd9b8 -37.5%, #061b17 100%);

// Font mixins
@mixin font-light($font-size: 1.6rem) {
  font-size: $font-size;
  font-weight: 300;
}

@mixin font-regular($font-size: 1.6rem) {
  font-size: $font-size;
  font-weight: 400;
}

@mixin font-medium($font-size: 1.6rem) {
  font-size: $font-size;
  font-weight: 500;
}

@mixin font-semibold($font-size: 1.6rem) {
  font-size: $font-size;
  font-weight: 600;
}

@mixin font-bold($font-size: 1.6rem) {
  font-size: $font-size;
  font-weight: 700;
}

@mixin d-flex($jc: space-between) {
  display: flex;
  justify-content: $jc;
  align-items: center;
}

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin font-black($font-size: 1.6rem) {
  font-size: $font-size;
  font-weight: 900;
}
