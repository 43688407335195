@import 'styles/variables.scss';

.insightsHero {
  width: 100%;
  padding: 80px 120px;
  background-color: $color-black-1;
  color: $color-grey-6;

  &Heading {
    color: $color-grey-6;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%;
    max-width: 60%;
    padding-bottom: 10px;
  }

  &Content {
    color: $color-grey-8;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    max-width: 60%;
  }
}

@media screen and (max-width: 800px) {
  .insightsHero {
    padding: 40px 16px;

    &Heading {
      font-size: 24px;
      max-width: 100%;
    }

    &Content {
      font-size: 16px;
      max-width: 100%;
    }
  }
}
