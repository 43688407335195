@import 'styles/variables.scss';

.ourField {
  // background: $color-black-1;
  background: $color-blue-0;
  padding: 48px 16px;

  &BgImage {
    background-image: url('../../assets/images/bgImage.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
    height: 582px;
    margin: 0 0 80px 0;
    padding: 100px 16px;

    h2 {
      color: $color-white-0;
      @include font-bold(4.8rem);
      max-width: 604px;
      margin: 16px 0 12px;
    }

    p {
      color: var(--gray-grey-6, $color-grey-6);
      @include font-regular(3rem);
      line-height: 150%;
      margin-bottom: 24px;
    }

    button {
      padding: 12px 24px;
      border-radius: 6px;
      background: $color-blue-2;
      color: var(--secondary-secondary-1, $color-black-1);
      text-align: center;
      @include font-medium(1.6rem);
      border: none;
      cursor: pointer;
      line-height: 124%;
      transition: all 0.2s ease;
      &:hover {
        background: $color-black-1;
        color: $color-grey-11;
        box-shadow: 0 0 0 1px inset $color-blue-2;
      }
    }
  }

  &Container {
    @include d-flex($jc: normal);
    gap: 32px;
  }

  &Experience {
    text-align: center;
    flex-basis: 25%;
    align-self: flex-start;
    @include d-flex($jc: center);
    flex-direction: column;
    position: relative;
    overflow: hidden;
    padding: 0 32px 0 0;
    border-right: 1px solid rgba(5, 26, 22, 0.15);

    &:last-of-type {
      padding: 0;
      border: none;

      &::after {
        display: none;
      }
    }

    &:nth-child(2) {
      p {
        max-width: 170px;
      }
    }

    // &::after {
    //   content: "";
    //   width: 1px;
    //   height: 100%;
    //   position: absolute;
    //   background: rgba(5, 26, 22, 0.15);
    //   right: 0;
    // }

    label {
      @include font-bold($font-size: 4.8rem);
      color: $color-grey-4;
      line-height: 124%;
      // background: $color-green-4;
      border-radius: 100px;
      // border: 1px solid $color-green-5;
      // padding: 12px 22px;
      flex-basis: 50%;
      width: fit-content;
      margin: 0 auto 8px;

      background: $linear-gradient-7;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      .countSymbol {
        @include font-bold($font-size: 3.6rem);
      }
    }

    p {
      color: $color-black-4;
      line-height: 140%;
      font-size: 18px;
    }
  }
}

.centerLine {
  display: none;
}

// responsive
@media (max-width: 768px) {
  .ourField {
    padding: 40px 16px;
    position: relative;
    z-index: 2;

    &BgImage {
      background-position: 90%;
      height: 437px;
      margin: 0 0 50px 0;

      h2 {
        @include font-bold(2.8rem);
        max-width: 100%;
        margin: 8px 0 10px;
      }

      p {
        @include font-regular(2rem);
        line-height: 110%;
        margin-bottom: 20px;
      }
      button {
        padding: 10px 20px;
        @include font-medium(1.4rem);
        border: none;
        line-height: 124%;
      }
    }

    &Container {
      @include d-flex($jc: space-between);
      flex-wrap: wrap;
      gap: 0;

      &:last-of-type {
      }
    }

    &Experience {
      flex-basis: 44.3%;
      padding: 0;
      border-right: none;

      &:nth-child(1) {
        padding: 0 0 18px 0;
      }

      &:nth-child(2) {
        padding: 0 0 18px 0;
      }

      &:nth-child(3) {
        padding: 26px 0 0 0;
      }

      &:nth-child(4) {
        padding: 26px 0 0 0;
      }

      label {
        font-size: 3.2rem;
        padding: 0;
        margin: 0 auto 4px;

        .countSymbol {
          font-size: 2.4rem;
        }
      }

      p {
        font-size: 1.4rem;
      }
    }
  }

  .centerLine {
    display: block;
    position: absolute;
    width: calc(100% - 32px);
    height: 1px;
    background: rgba(5, 26, 22, 0.15);
    top: 50%;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
}
