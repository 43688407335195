@import 'styles/variables.scss';

.mission {
  background-image: url(../../assets/images/about-us/missionBgImg.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  &Heading {
    margin-bottom: 64px;
    h3 {
      @include font-medium($font-size: 2rem);
      line-height: 150%;
      color: $color-green-1;
      text-align: center;
      margin-bottom: 10px;
    }
    h2 {
      @include font-bold($font-size: 4rem);
      line-height: 124%;
      color: $color-black-1;
      text-align: center;
    }
  }
  &Content {
    @include d-flex($jc: space-between);
    &Details {
      flex-basis: 53.5%;
      p {
        @include font-regular($font-size: 2rem);
        line-height: 150%;
        color: $color-grey-0;
        &:nth-child(2) {
          padding: 16px 0;
        }
      }
    }
    &Img {
      flex-basis: 38.2%;

      img {
        height: 550px;
      }
    }
  }
}

// media query
@media (max-width: 768px) {
  .mission {
    padding: 40px 16px;
    background-size: 50%;
    &Heading {
      margin-bottom: 48px;
      h3 {
        line-height: 124%;
        font-size: 14px;
      }
      h2 {
        font-size: 24px;
      }
    }
    &Content {
      flex-direction: column-reverse;
      gap: 16px;
      &Img {
        img {
          height: auto;
        }
      }
      &Details {
        p {
          line-height: 124%;
          font-size: 16px;
        }
      }
    }
  }
}
