@import 'styles/variables.scss';

.home {
  background: url('../../assets/images/home/Banner.png');
  padding: 76px 0 0 0;

  // &Bg {
  //   background-color: #051a16;
  //   padding-top: 76px;
  // }

  &Banner {
    padding: 0;
    background-color: $color-green-2;

    &One {
      // height: 100vh;
      height: calc(100vh - 76px);
      display: flex;
      flex-direction: column;
      align-items: center;

      justify-content: center;
      // background-image: url(../../assets/images/home/bgCircle.png);
      // background-size: 60%;
      // background-position: 50% 0;
      // background-repeat: no-repeat;
      // position: sticky;
      // top: 0;

      &Title {
        @include d-flex();
        flex-direction: column;

        div {
          background: linear-gradient(176deg, #fff 21.77%, #a0a0a0 96.53%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        h1 {
          color: $color-green-0;
          @include font-bold($font-size: 7.2rem);
          line-height: 108%;
          max-width: 1000px;
          text-align: center;
          background: linear-gradient(176deg, #fff 32.49%, #a0a0a0 96.53%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        p {
          padding-top: 32px;
          max-width: 653px;
          margin: 0 auto 30px;
          color: #fff;
          @include font-regular(2.2rem);
          line-height: 156%;
          text-align: center;
        }
        span {
          background-image: url(../../assets/images/home/globe.png);
          background-size: 100% 100%;
          height: 111px;
          background-position: top;
          background-repeat: no-repeat;
          display: inline-block;
          width: 111px;
          position: relative;
          top: 2.5rem;
          transition: all 0.5s ease;
        }
      }
    }

    &Two {
      position: fixed;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: url(../../assets/images/home/HomeBackground.png);
      // background-attachment: scroll;
      background-size: cover;
      clip-path: circle(0px at center);
      h1 {
        color: $color-white-0;
        @include font-bold($font-size: 7.2rem);
        line-height: 108%;
        max-width: 1000px;
        text-align: center;
      }
      p {
        @include font-medium($font-size: 2.4rem);
        color: $color-green-1;
        padding-top: 32px;
        max-width: 1000px;
        text-align: center;
        line-height: 150%;
        visibility: hidden;
      }
      span {
        background-image: url(../../assets/images/home/globe.png);
        background-size: 100% 100%;
        height: 111px;
        background-position: top;
        background-repeat: no-repeat;
        display: inline-block;
        width: 111px;
        position: relative;
        top: 2.5rem;
        transition: all 0.5s ease;
      }
    }
    &Container {
      position: relative;
      // margin-top: 165vh;
      background: white;
      h2 {
        font-size: 2.5em;
        margin-bottom: 20px;
      }
    }
  }
}

// media query
@media (max-width: 768px) {
  .home {
    &Banner {
      &One {
        background-size: 100%;
        background-position: 50% 50%;
        // height: calc(100vh - 67px);
        padding: 0 16px;
        h1 {
          font-size: 4rem;
          line-height: 124%;
        }
        p {
          font-size: 1.6rem;
          line-height: 156%;
          font-weight: 400;
          padding-top: 12px;
        }
        span {
          height: 66px;
          width: 66px;
          margin-bottom: 5px;
        }
      }
      &Two {
        top: 0;
        padding: 0 16px;
        // height: calc(100vh - 67px);
        h1 {
          font-size: 4rem;
          line-height: 124%;
        }
        p {
          font-size: 1.6rem;
          line-height: 156%;
          font-weight: 400;
          padding-top: 12px;
        }
        span {
          height: 66px;
          width: 66px;
        }
      }
    }
  }
}
