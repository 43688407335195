@import 'styles/variables.scss';

.newsBanner {
  position: relative;
  padding: 0;

  .container {
    max-width: 100%;
    margin: 0;
    height: 100%;
    padding: 120px;
  }

  .showingText {
    color: $color-white-0;
    @include font-regular($font-size: 1.3rem);
  }

  .activePage {
    color: $color-white-0;
  }

  .inactivePage {
    color: $color-grey-19;
  }

  .counterNumber.activeNews {
    border-radius: 6px;
    padding: 10px 24px;
    border: 0.6px solid rgba(255, 255, 255, 0.5);
    background: linear-gradient(
      92deg,
      rgba(255, 255, 255, 0.14) 5.2%,
      rgba(255, 255, 255, 0.05) 103.57%
    );
    backdrop-filter: blur(4.5px);
    gap: 6px;
  }

  .bannerContainer0 {
    background-image: url(../../assets/images/news/newsBannerWeb.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    padding: 120px 0;

    @include d-flex($jc: center);
    align-items: flex-start;
    flex-direction: column;
    padding: 0 120px;
  }

  .bannerContainer1 {
    background-image: url(../../assets/images/news/newsBanner2.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    padding: 120px 0;

    @include d-flex($jc: center);
    align-items: flex-start;
    flex-direction: column;
    padding: 0 120px;
    background-position: center;
  }

  &Contents {
    &Button {
      @include d-flex($jc: flex-start);
      gap: 6px;
      border-radius: 26px;
      outline: 0.6px solid rgba(255, 255, 255, 0.5);
      background: linear-gradient(
        92deg,
        rgba(255, 255, 255, 0.14) 5.2%,
        rgba(255, 255, 255, 0.05) 103.57%
      );
      backdrop-filter: blur(4.5px);
      width: fit-content;
      height: 32px;
      padding: 8px 12px;

      span {
        @include font-medium($font-size: 1.2rem);
        color: $color-white-0;
      }
    }

    h1 {
      color: $color-white-0;
      line-height: 124%;
      @include font-bold($font-size: 4.8rem);
      max-width: 929px;
      padding: 16px 0 14px;
    }

    p {
      color: $color-grey-18;
      @include font-light($font-size: 1.8rem);
      line-height: 140%;
      max-width: 765px;
      padding-bottom: 50px;
    }
  }

  @media (max-width: 768px) {
    height: calc(100vh - 66px);
    margin-top: 66px;

    .bannerContainer0 {
      height: calc(100vh - 66px);
      padding: 0 16px;
    }

    .bannerContainer1 {
      height: calc(100vh - 66px);
      padding: 0 16px;
    }

    &Contents {
      position: inherit;
      transform: inherit;

      h1 {
        @include font-bold($font-size: 2.4rem);
        max-width: 100%;
      }

      p {
        @include font-light($font-size: 1.6rem);
        max-width: 100%;
      }
    }
  }
}

.splide__track--draggable {
  // margin-bottom: 40px;
}

.splide__slide {
  // width: fit-content !important;
}

button.splide__arrow::before {
  border: 1px solid #19147b;
}

button.splide__arrow.splide__arrow--next::after {
  background-image: url('../../assets/images/news/afterArrow.svg') !important;
  display: none !important;
}

button.splide__arrow.splide__arrow--prev::after {
  background-image: url('../../assets/images/news/preArrow.svg') !important;
  display: none !important;
}

.splide__pagination {
  display: none !important;
}

.splide__arrows {
  position: relative;
  @include d-flex(center);
}

.splide__arrows--ltr {
  position: relative;
  @include d-flex(center);
}

.splide__arrow--next {
  top: 27.2em;
  right: 44%;
  //   left: 0;
  bottom: 0;
}

.splide__arrow--prev {
  top: 27.2em;
  left: 44%;
  bottom: 0;
}

button.splide__arrow {
  display: none !important;
}

.disabled {
  cursor: default !important;
  opacity: 0.3;
}

.counter {
  position: relative;
  margin-top: 50px;
  width: 100%;

  &Img {
    border: 1px solid $color-white-0;
    padding: 10px;
    cursor: pointer;
    border-radius: 26px;
    background: linear-gradient(
      92deg,
      rgba(255, 255, 255, 0.14) 5.2%,
      rgba(255, 255, 255, 0.04) 103.57%
    );
    backdrop-filter: blur(4.5px);
  }

  span {
    color: #888;
    @include font-medium($font-size: 1.4rem);
    line-height: 150%;
    @include d-flex(flex-end);
    gap: 16px;
  }
}

.counter {
  &Responsive {
    display: none;
  }
}

@media (max-width: 1100px) {
  .splide__track--draggable {
    margin-bottom: 10px;
  }

  .splide__arrow--next {
    right: 44%;
    //   left: 0;
    bottom: 0;
  }
}

@media (max-width: 992px) {
  .splide__arrow--prev {
    top: 23.9em;
    left: 43%;
    bottom: 0;
  }

  .counter {
    display: none;
  }

  .counter {
    &Responsive {
      @include d-flex(center);
      flex-direction: column;
      margin-top: 50px;

      &Img {
        border-radius: 32px;
        border: 1px solid #19147b;
        padding: 8px;
        cursor: pointer;
      }

      span {
        color: #888;
        @include font-medium($font-size: 1.4rem);
        line-height: 150%;
        @include d-flex();
        gap: 12px;
      }
    }
  }

  .splide__arrow--next {
    top: 23.9em;
    right: 43%;
    bottom: 0;
  }
}
