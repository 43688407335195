@import 'styles/variables.scss';

.ourMissions {
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  background: $color-black-1;
  padding: 80px 120px;
  background-image: url('../../assets//images/about-us/spiralVector.png');
  background-repeat: no-repeat;
  background-position: left bottom;
  // margin-top: 76px;
}
.ourMissionsTitleSection {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
}
.ourMissionsTitleSection h2 {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  color: $color-grey-6;
}
.ourMissionsTitleSection p {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: $color-grey-1;
}
.rotaingEathContainer {
  flex-basis: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.rotaingEath {
  width: 424px;
  height: 424px;
  display: flex;
  object-fit: cover;
}
@media screen and (max-width: 1150px) {
  .ourMissions {
    padding: 40px 24px;
  }
}
@media screen and (max-width: 950px) {
  .ourMissions {
    padding: 40px 16px;
  }

  .rotaingEath {
    width: 324px;
    height: 324px;
    display: flex;
    object-fit: cover;
  }
}
@media screen and (max-width: 750px) {
  .ourMissions {
    padding: 40px 16px;
    height: 340px;
    background-size: 80% 40%;
    // margin-top: 62px;
  }
  .rotaingEathContainer {
    display: none;
  }
  .ourMissionsTitleSection {
    width: 100%;
    gap: 12px;
  }
  .ourMissionsTitleSection h2 {
    font-size: 24px;
  }
  .ourMissionsTitleSection p {
    font-size: 16px;
  }
}
