@import 'styles/variables.scss';

.mrvSoln {
  background: #051a16;

  .commonHeading {
    h1 {
      color: #dcf9f3;
    }

    p {
      color: $color-white-0;
      max-width: 815px;
      margin: 0 auto;
    }
  }

  &Video {
    width: 100%;

    &Element {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .commonHeading {
      p {
        max-width: 100%;
      }
    }

    &Video {
      width: 100%;
      @include d-flex(center);

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
