@import 'styles/variables.scss';
.productFeatures {
  width: 100%;
  @include d-flex($jc: space-between);
  align-items: flex-start;
  padding: 80px 120px;
  background-color: $color-grey-11;

  font-style: normal;
  color: $color-black-1;

  h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 124%;
  }

  h3 {
    font-size: 40px;
    font-weight: 700;
    line-height: 124%;
  }

  h4,
  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    color: $color-green-1;
  }
  p {
    color: $color-grey-0;
    font-weight: 400;
  }

  &Left {
    width: 100%;
    max-width: 100%;
    flex-basis: 27.9%;

    h4 {
      padding-bottom: 8px;
    }
  }

  &Right {
    width: 100%;
    max-width: 100%;
    flex-basis: 47.2%;
    @include d-flex($jc: center);
    flex-direction: column;
    gap: 36px;

    .textItem {
      @include d-flex($jc: center);
      flex-direction: column;
      align-items: flex-start;
      gap: 14px;
      width: 100%;
    }
  }
}

@media (max-width: 800px) {
  .productFeatures {
    flex-direction: column;
    padding: 40px 16px;
    gap: 32px;

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 14px;
    }

    p {
      font-size: 16px;
    }
    &Right {
      .textItem {
        gap: 4px;
      }
    }
  }
}
