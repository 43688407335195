@import 'styles/variables.scss';

.geoTree {
  &Heading {
    padding: 80px 0px 64px;
    max-width: 906px;
    margin: 0 auto;

    h4 {
      color: $color-grey-4;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      padding-bottom: 12px;
    }

    h3 {
      color: $color-grey-6;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
    }
  }

  &Carousel {
    .geoTreeSlider {
      padding-bottom: 80px;
    }
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 20%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(5, 26, 22, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      top: 0;
      left: 0;
      z-index: 2;
      pointer-events: all;
    }

    &::after {
      content: '';
      position: absolute;
      width: 20%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(5, 26, 22, 1) 100%
      );
      top: 0;
      right: 0;
      z-index: 2;
      pointer-events: all;
    }

    .slide {
      max-width: 720px;
      padding: 40px 24px;
      @include d-flex($jc: space-between);
      align-items: flex-start;
      flex-direction: column;
      color: $color-grey-6;
      position: relative;
      gap: 40px;
      height: unset;
      align-self: stretch;
      border-left: 1px solid $color-green-3;

      &::before {
        content: '';
        width: 1px;
        height: 28px;
        background-color: $color-grey-0;
        position: absolute;
        left: -1px;
        top: 50px;
      }
      &Content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
      }
      h4,
      h5 {
        font-size: 30px;
        font-weight: 500;
        line-height: normal;
      }

      p {
        font-size: 24px;
        line-height: 150%;
        font-weight: 500;
        color: $color-grey-1;
      }

      &Image {
        max-width: 100%;
        width: 100%;
        text-align: center;

        img {
          width: 300px;
          height: 300px;
          overflow: hidden;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
    .paginationArrows {
      margin-top: 80px;
      @include d-flex($jc: center);
      gap: 32px;
      .swiperPagination {
        margin-top: 0px;
        background-color: transparent !important;
        @include d-flex($jc: center);
        width: fit-content;

        .swiper-pagination-bullet {
          background-color: $color-black-1;
          stroke-width: 1px;
          border: 1px solid $color-grey-0;
          width: 12px;
          height: 12px;
          opacity: 1;
          transition: all 300ms cubic-bezier(0.38, 0.39, 0.6, 0.59);
        }

        .swiper-pagination-bullet-active {
          transition: all 300ms ease-in-out;
          background-color: $color-grey-0;
          stroke-width: 1px;
          border: 1px solid $color-grey-0;
          width: 38px;
          height: 12px;
          opacity: 1;
          border-radius: 8px;
        }
      }

      .swiper-button-next,
      .swiper-button-prev {
        cursor: pointer;
      }

      .swiper-button-next {
        rotate: 180deg;
      }

      .swiper-button-disabled {
        opacity: 0.7;
        cursor: unset;
      }
    }
  }
}

@media (max-width: 800px) {
  .geoTree {
    &Heading {
      max-width: 100%;
      padding: 40px 0px 32px;
      h4 {
        font-size: 14px;
      }

      h3 {
        font-size: 24px;
      }
    }

    &Carousel {
      padding-left: 16px;

      &::after,
      &::before {
        content: none;
      }

      .geoTreeSlider {
        padding-bottom: 40px;
      }

      .slide {
        padding: 32px 12px;
        gap: 8px;
        h4 {
          font-size: 24px;
        }

        h5 {
          font-size: 20px;
        }

        p {
          font-size: 16px;
        }

        &::before {
          top: 34px;
        }

        &Image {
          padding-top: 0;

          img {
            width: 237px;
            height: 237px;
            overflow: hidden;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }

      .paginationArrows {
        margin-top: 8px;
        .swiperPagination {
          .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
          }

          .swiper-pagination-bullet-active {
            width: 24px;
            height: 8px;
          }
        }

        .swiper-button-next,
        .swiper-button-prev {
          width: 16px;
          height: 16px;
          display: none;
        }
      }
    }
  }
}
