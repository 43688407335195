@import 'styles/variables.scss';

.insightsArticles {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 120px;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  h4 {
    color: $color-green-1;
    text-align: center;
    font-feature-settings:
      'clig' off,
      'liga' off;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  h3 {
    color: $color-black-1;
    text-align: center;

    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%;
  }

  .insightsCards {
    padding-top: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    max-width: 100%;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 800px) {
  .insightsArticles {
    padding: 40px 16px;

    h4 {
      font-size: 14px;
      line-height: 124%;
    }

    h3 {
      font-size: 24px;
      max-width: 328px;
    }

    .insightsCards {
      padding-top: 24px;
      flex-direction: column;
    }
  }
}
