@import '/src/styles/variables.scss';

// Getin Touch Section

.contact {
  background-color: $color-black-1;
  background-image: url(../../assets/images/getinTouch.svg);
  background-repeat: no-repeat;
  background-position: 100% 1%;
  // margin-top: 76px;

  &Us {
    padding-top: 76px;
    background-color: #051a16;
  }

  &Heading {
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;

    h2 {
      @include font-bold($font-size: 4.8rem);
      line-height: 124%;
      color: $color-grey-6;
      margin-bottom: 8px;
    }

    h4 {
      @include font-medium($font-size: 2.4rem);
      color: $color-grey-13;
      line-height: 150%;
      text-decoration: underline;
      text-underline-offset: 6px;
      text-transform: lowercase;
      margin-bottom: 18px;
    }

    p {
      @include font-medium($font-size: 2.4rem);
      color: var(--gray-grey-2, #a4b7b3);
      line-height: 150%;
      max-width: 658px;
    }
  }

  &Locations {
    @include d-flex($jc: space-between);
    gap: 24px;
    align-items: initial;
    padding-top: 80px;
  }

  &Contents {
    padding: 30px 30px 30px 0px;

    img {
      max-width: 100px;
    }

    h4 {
      @include font-medium($font-size: 3rem);
      color: var(--gray-grey-6, #e8e9e9);
      margin: 16px 0px 8px;
    }

    p {
      @include font-regular($font-size: 2rem);
      line-height: 150%;
      color: var(--gray-grey-3, #a1a5a5);
      max-width: 324px;
    }
  }

  &Clock {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-green-8;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.5);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border: 3px solid $color-white-0;
      border-radius: 50%;
      background-color: $color-green-8;
    }

    .dial {
      position: relative;
      top: -12px;
      left: 2px;
      width: 3px;
      height: 24px;
      background-color: $color-white-0;
      border-radius: 20px;
      transform-origin: bottom;
    }

    &Hours {
      top: -10px !important;
      left: -1px !important;
      height: 18px !important;
    }
  }
}

// responsive
@media (max-width: 768px) {
  .contact {
    padding: 57px 16px 40px;
    background-position: 176% 8%;
    background-size: 72%;

    &Us {
      padding-top: 67px;
    }

    &Contents {
      padding: 0px;
    }

    &Heading {
      margin-bottom: 58px;

      h2 {
        font-size: 3.6rem;
        margin-bottom: 8px;
      }

      h4 {
        font-size: 2.4rem;
        margin-bottom: 12px;
      }

      p {
        font-size: 1.6rem;
      }
    }

    &Locations {
      flex-wrap: wrap;
      padding-top: 40px;
    }

    &Contents {
      h4 {
        font-size: 2.4rem;
        margin-top: 8px;
      }

      p {
        font-size: 1.6rem;
      }
    }

    &Clock {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-green-8;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 0.5);
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 2.5px;
        height: 2.5px;
        border: 1.5px solid $color-white-0;
        border-radius: 50%;
        background-color: $color-green-8;
      }

      .dial {
        position: absolute;
        left: 48%;
        top: 18%;
        width: 1.5px;
        height: 12px;
        transform-origin: bottom;
        background-color: $color-white-0;
        border-radius: 20px;
      }

      &Hours {
        top: 30% !important;
        left: 49% !important;
        height: 7.5px !important;
      }
    }
  }
}
