@import 'styles/variables.scss';

.carousels {
  background-color: $color-black-1;
  padding: 120px 0 80px;
  position: relative;
  overflow: hidden;

  .swiper {
    overflow: unset !important;
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    z-index: 0;
    width: fit-content;
    position: relative;
    scale: 1;
    aspect-ratio: 1.65/1;
    border-radius: 8px;
    overflow: hidden;
    transition: scale 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    img {
      width: 530px;
      height: 100%;
    }

    .overlay {
      cursor: pointer;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(
        180deg,
        rgba(4, 16, 14, 0.04) 0%,
        rgba(4, 16, 14, 0.8) 100%
      );
    }

    .slideContent {
      position: absolute;
      z-index: 10;
      color: $color-grey-6;
      bottom: 10px;
      left: 10px;
      font-size: 20px;
      font-weight: 500;
      padding: 32px 32px 10px 32px;

      h5 {
        font-weight: 700;
      }
    }
  }

  .swiper-slide-active {
    z-index: 2;
    scale: 1.4;
  }

  .swiper-slide-next,
  .swiper-slide-prev {
    z-index: 1;
    scale: 1.2;
  }

  .swiperPagination {
    margin-top: 80px;
    background-color: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper-pagination-bullet {
      background-color: $color-black-1;
      stroke-width: 1px;
      border: 1px solid $color-grey-0;
      width: 12px;
      height: 12px;
      opacity: 1;
      transition: all 300ms cubic-bezier(0.38, 0.39, 0.6, 0.59);
    }

    .swiper-pagination-bullet-active {
      transition: all 300ms ease-in-out;
      background-color: $color-grey-0;
      stroke-width: 1px;
      border: 1px solid $color-grey-0;
      width: 38px;
      height: 12px;
      opacity: 1;
      border-radius: 8px;
    }
  }
  .paginationArrows {
    margin-top: 80px;
    @include d-flex($jc: center);
    gap: 32px;
    .swiperPagination {
      margin-top: 0px;
      background-color: transparent !important;
      @include d-flex($jc: center);
      width: fit-content;

      .swiper-pagination-bullet {
        background-color: $color-black-1;
        stroke-width: 1px;
        border: 1px solid $color-grey-0;
        width: 12px;
        height: 12px;
        opacity: 1;
        transition: all 300ms cubic-bezier(0.38, 0.39, 0.6, 0.59);
      }

      .swiper-pagination-bullet-active {
        transition: all 300ms ease-in-out;
        background-color: $color-grey-0;
        stroke-width: 1px;
        border: 1px solid $color-grey-0;
        width: 38px;
        height: 12px;
        opacity: 1;
        border-radius: 8px;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      cursor: pointer;
    }

    .swiper-button-next {
      rotate: 180deg;
    }

    .swiper-button-disabled {
      opacity: 0.7;
      cursor: unset;
    }
  }
}

@media screen and (max-width: 800px) {
  .carousel {
    padding: 80px 16px 40px;

    .swiper-slide {
      z-index: 0;
      width: fit-content;
      position: relative;
      scale: 1;
      aspect-ratio: 0.69/1;

      img {
        width: 250px;
        border-radius: 8px;
        height: 100%;
        object-fit: cover;
      }

      .slideContent {
        position: absolute;
        z-index: 10;
        color: $color-grey-6;
        bottom: 10px;
        left: 10px;
        font-size: 17px;
        font-weight: 500;
        padding: 10px;
      }
    }

    .swiper-slide-active {
      z-index: 2;
      scale: 1.23;
    }

    .swiper-slide-next,
    .swiper-slide-prev {
      scale: unset;
    }

    .swiperPagination {
      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
      }

      .swiper-pagination-bullet-active {
        width: 24px;
        height: 8px;
      }
    }
    .paginationArrows {
      margin-top: 64px;
      .swiperPagination {
        .swiper-pagination-bullet {
          width: 8px;
          height: 8px;
        }

        .swiper-pagination-bullet-active {
          width: 24px;
          height: 8px;
        }
      }

      .swiper-button-next,
      .swiper-button-prev {
        width: 16px;
        display: none;
        height: 16px;
      }
    }
  }
}
