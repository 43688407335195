@import 'styles/variables.scss';

.partners {
  background: #fff;
  position: relative;

  &Details {
    text-align: center;

    h3 {
      color: $color-black-4;
      @include font-medium($font-size: 2rem);
      line-height: 150%;
      margin-bottom: 16px;
      @include d-flex(center);
      gap: 8px;
    }

    h4 {
      color: $color-black-1;
      @include font-bold($font-size: 3.8rem);
      line-height: 124%;
      margin-bottom: 32px;
    }
  }

  &Logo {
    @include d-flex($jc: center);
    flex-wrap: wrap;
    // height: 95px;
    overflow-y: hidden;

    // &Style {
    //   display: flex;
    //   gap: 18px;
    //   flex-wrap: nowrap;
    //   overflow: hidden;
    // }

    span {
      overflow: hidden;
      // position: relative;
      // max-width: 201px;

      img {
        // object-fit: cover;
        height: 95px;
      }

      // &::after {
      //   content: "";
      //   position: absolute;
      //   width: 1px;
      //   height: 58%;
      //   background: $linear-gradient-1;
      //   right: 0;
      //   bottom: 0;
      // }

      // &:last-child::after {
      //   display: none;
      // }
    }

    &LastRow {
      display: flex;
      flex-wrap: nowrap;

      span {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 1.8px;
          height: 58%;
          background: $linear-gradient-1;
          top: 0;
          bottom: 0;
        }

        &:nth-child(4):after {
          display: none;
        }
      }
    }
  }

  &Mobile {
    display: none;
  }
}

.rfm-marquee-container {
  overflow: hidden;
}

// responsive
@media (max-width: 576px) {
  .partners {
    padding: 40px 16px;
    z-index: 2;

    h3 {
      @include font-medium($font-size: 1.4rem);
      margin-bottom: 10px;
      line-height: 124%;
    }

    h4 {
      margin: 0 auto 32px;
      font-size: 2.4rem;
      max-width: 328px;
    }

    &Logo {
      // display: none;
    }

    &Mobile {
      display: flex;
      flex-wrap: wrap;

      span {
        flex-basis: 45%;
        flex-grow: 1;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: 100%;
          background: $linear-gradient-2;
          right: 0;
          bottom: 0;
        }

        &:nth-child(even)::after {
          display: none;
        }

        &:nth-child(1)::after {
          background: $linear-gradient-3;
          bottom: 0;
        }

        &:nth-child(n + 8)::after {
          background: $linear-gradient-4;
          top: 0;
        }

        &::before {
          content: '';
          position: absolute;
          width: 120%;
          height: 1px;
          background: $linear-gradient-1;
          right: 0;
          bottom: 0;
        }

        &:nth-child(even)::before {
          // left: 0;
          background: $linear-gradient-3;
        }

        &:last-child::before {
          display: none;
        }

        &:nth-child(9)::before {
          display: none;
        }
      }
    }
  }
}
