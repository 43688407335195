@import 'styles/variables.scss';

.header {
  background: $color-blue-0;
  // box-shadow: 0px 1px 0 0 $color-grey-1;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  padding: 0 16px;
  transition: all 0.5s ease;

  &Contact {
    background: rgba(5, 26, 22, 0.5);
    backdrop-filter: blur(14px);

    .headerElements {
      .listStyle {
        li {
          color: #bbc9c6;

          &:hover {
            color: #fff;
          }
        }
        .navActive {
          color: #fff;
        }
      }
    }
    .headerContactUs {
      padding: 12px 24px !important;
      @include font-medium(1.6rem);
      color: #051a16;
      // &:hover {
      //   background: $color-black-1;
      //   color: $color-grey-11;

      //   &::before {
      //     content: "";
      //     position: absolute;
      //     z-index: -1;
      //     inset: -1px;
      //     border-radius: 6px;
      //     background: $color-blue-2;
      //   }
      // }
    }
  }

  &Container {
    @include d-flex($jc: space-between);
    max-width: 1232px;
  }

  &Logo {
    width: 142px;
    height: 35px;
  }

  &Elements {
    flex-basis: 29.9%;

    .listStyle {
      @include d-flex($jc: normal);
      list-style-type: none;
      gap: 44px;
    }

    li {
      cursor: pointer;
      @include font-medium;
      line-height: 150%;
      color: $color-grey-0;
      padding: 26px 0;

      a {
        white-space: nowrap;
      }

      &:hover {
        color: $color-green-3;
      }
    }

    .navActive {
      color: $color-green-3;
      @include font-bold;
      position: relative;
      line-height: 124%;

      &::after {
        content: '';
        position: absolute;
        background: $color-blue-2;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: 8px;
      }
    }
    .responsiveButton {
      display: none;
    }
  }

  &ContactUs {
    // @include font-medium;
    // color: $color-black-1;
    // padding: 12px 24px;
    // line-height: 124%;
    // position: relative;
    // border-radius: 8px;
    // border: 4px solid rgba(255, 255, 255, 0.25);
    // background: #2cd9b8;
    // box-shadow: 0px 14px 20px 0px rgba(255, 255, 255, 0.07);

    // &:hover {
    //   background: $color-green-2;

    //   &::before {
    //     content: "";
    //     position: absolute;
    //     z-index: -1;
    //     inset: -1px;
    //     border-radius: 6px;
    //     background: $color-blue-2;
    //   }
    // }
  }

  &MenuIcon {
    display: none;
  }
}
.hidden {
  transform: translateY(-105%) !important;
  transition: all 0.5s ease-in-out;
}

// responsive
@media (max-width: 768px) {
  .header {
    position: relative;
    z-index: 599;
    padding: 16px;

    &Open {
      background-color: $color-black-1;
      border-bottom: 1px solid #313131;
    }

    &Elements {
      padding: 0px 16px 16px 16px;
      position: absolute;
      flex-direction: column;
      width: 100%;
      left: 0;
      top: 67.5px;
      overflow: hidden;
      transition: all 0.5s ease;
      z-index: 999;
      height: 0;

      .navActive {
        color: $color-grey-4;

        &::after {
          display: none;
        }
      }
      li {
        color: $color-grey-14;
        padding: 16px;
        border-bottom: 1px solid #313131;
        width: 100%;

        &:hover {
          color: $color-grey-4;
        }
      }
      .responsiveButton {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-top: auto;
      }
    }
    .headerOpen {
      height: var(--header-height, 100vh);
      display: flex;
    }
    .listStyle {
      width: 100%;
      flex-direction: column;
      gap: 0px;
    }

    &ContactUs {
      display: none;
    }

    &MenuIcon {
      display: block;
      cursor: pointer;
    }
  }
}
.no-wrap {
  white-space: nowrap;
}
