.videoModal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 998;

  &Container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 11px;
    position: relative;
    width: 100%;

    img {
      position: absolute;
      top: -5%;
      right: 16.5%;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 600px) {
  .videoModal {
    &Container {
      position: relative;
      top: -5%;
      img {
        top: -10%;
        right: 6%;
      }
    }
  }
}
