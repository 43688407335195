@import '/src/styles/variables.scss';

.video {
  margin: 0 auto;
  width: 100%;
  transition: all 0.5s ease;
  border-radius: 10px;
  min-height: 100%;
  object-fit: fill;
}
.responsiveLine {
  margin: 0 0 0 20px;

  &::after {
    content: '';
    width: 3px;
    height: 0%;
    background: #4c8feb;
    position: absolute;
    top: 18px;
    left: 20px;
  }

  &::before {
    content: '';
    position: absolute;
    background: #fff;
    width: 3px;
    left: 20px;
    height: calc(100% - 36px);
  }
}

.activeClass .responsiveLine::after {
  animation: progress 5s linear forwards;
}

@keyframes progress {
  0% {
    height: 0%;
  }

  100% {
    height: calc(100% - 36px);
  }
}

.responsiveCard {
  @include d-flex();
  flex-direction: column;
  gap: 22px;

  &Full {
    width: 100%;
  }

  &Details {
    .activeClass {
      border-radius: 8px;
      background: var(--Accent-Color-Accent-4, #c8d9eb);
      position: relative;
    }
  }

  &Before {
    .activeClass {
      margin: 0 0 0 20px;
    }
  }

  &Video {
    width: 100%;
    position: relative;
    @include d-flex();
    margin: 0 auto;
    align-items: stretch;
    animation: fadeIn 5s ease-in-out forwards infinite;
  }

  &Title {
    border-radius: 8px;
    border: 1px solid #d8d8d8;
    padding: 18px;
    cursor: pointer;
    background: transparent;

    .heading {
      margin: 0 !important;
    }

    h3 {
      color: var(--Secondary-Secondary-1, #051a16);
      @include font-bold(1.6rem);
      margin: 13px 0 6px;
      line-height: normal;
    }

    p {
      color: #626b70;
      @include font-regular(1.6rem);
      line-height: 150%; /* 24px */
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
