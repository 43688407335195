@import 'styles/variables.scss';

.in-main-container {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.in-section {
  padding: 0 !important;
  margin: 0 !important;
  margin-top: 75px !important;
}
.in-container {
  padding: 80px 275px;
  background: $color-green-2;
}
.in-details-title {
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  margin-bottom: 40px;
}
.in-blog-writer-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.in-blog-writer-icon {
  width: 36.549px;
  height: 40.406px;
  border-radius: 50%;
}
.in-blog-writer-name {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 124%;
  color: $color-black-1;
}
.in-blog-writer-role {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 124%;
  color: $color-grey-0;
}

.in-details-subtitle-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}
p {
  @include font-regular($font-size: 2rem);
  color: $color-green-0;
  line-height: 150%;
}
ul {
  @include font-regular($font-size: 2rem);
  color: $color-green-0;
  line-height: 150%;
  padding-left: 22px;
}
.in-blog-image {
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 40px 0px 40px;
  overflow: hidden;

  img {
    width: 100%;
    height: 450px;
    object-fit: cover;
    border-radius: 8px;
    transition: all 0.5s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
}
.in-details-subtitle {
  font-size: 2.8rem;
  font-weight: 700;
  line-height: normal;
  color: $color-black-1;
  margin-bottom: 40px;
}
.in-details-description {
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: $color-green-0;
}
.in-blogshare-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.in-share-icon-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}
.in-share-icon {
  width: 38px;
  height: 38px;
  background: $color-white-0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.in-suggetion-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 64px;
  background: $color-white-0;
  padding: 80px 100px;
}
.in-suggetion-card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

@media (max-width: 1250px) {
  .in-container {
    padding: 80px 175px;
  }
  .in-suggetion-container {
    padding: 60px 80px;
  }
}
@media (max-width: 1050px) {
  .in-container {
    padding: 80px 100px;
  }
  .in-suggetion-container {
    padding: 60px 16px;
  }
}
@media (max-width: 750px) {
  .in-container {
    padding: 60px 16px;
  }
  .in-blog-image {
    height: 340px;
    margin: 0;
    &:hover {
      scale: 1;
    }
  }
  .in-blog-writer-container {
    margin: 20px 0 16px;
  }
  .in-blog-image img {
    height: 340px;
  }
  .in-details-title {
    font-size: 2.4rem;
    margin-bottom: 0;
  }
  .in-section {
    margin-top: 40px !important;
  }
  .in-main-container {
    gap: 0;
  }
  .in-suggetion-card-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }
  .in-suggetion-container {
    padding: 40px 16px;
    gap: 24px;
  }
  .in-details-subtitle {
    font-size: 2.8rem;
    margin: 16px 0;
  }
  .in-details-description {
    font-size: 1.6rem;
  }
}
