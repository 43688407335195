@import 'styles/variables.scss';

.carousel {
  padding: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100%;

  &Wrapper {
    width: 100%;
    overflow: hidden;
  }

  &Content {
    position: relative;
    // height: 100vh;
  }

  .control-dots {
    // display: none;
  }

  h2 {
    color: #fff;
    @include font-bold(3.8rem);
    line-height: 52px;
    max-width: 646px;
    margin: 0 auto;
    position: absolute;
    top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
  }

  p {
    color: #fff;
    @include font-bold(3.8rem);
    line-height: 52px;
  }

  .buttonsWrap {
    position: absolute;
    bottom: 80px;
    right: 0;
    left: 0;
    @include d-flex(center);
    gap: 44px;
  }
}
.carousel-slider .control-arrow {
  display: none;
}

.active {
  border-radius: 40px;
  background: var(--Primary-Primary-3, #b6f2e6) !important;
  backdrop-filter: blur(9.5px) !important;
  color: $color-black-1 !important;
}

.sliderButton {
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(9.5px);
  padding: 8px 20px;
  color: #fff;
  @include font-medium(1.8rem);
  line-height: normal;
  cursor: pointer;
}

.control-dots {
  @include d-flex(center);
  margin-bottom: 80px !important;
}

.progress {
  width: 62px;
  height: 1.5px;
  background: #656565;

  &:last-of-type {
    display: none;
  }

  &Wrap {
    animation: load 5s normal forwards;
    background: #2cd9b8;
    box-shadow: 0 10px 40px -10px #fff;
    height: 3px;
  }
}

.isActiveProgress {
  background: #2cd9b8;
}

@keyframes load {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.carousel .slide img {
  height: 100vh;
  // width: 100%;
  object-fit: cover;
}

@media (max-width: 992px) {
  .carousel .slide img {
    height: 80vh;
  }

  .carousel {
    h2 {
      font-size: 24px;
      line-height: 124%;
      padding: 0 16px;
      top: 40px;
    }
    p {
      font-size: 24px;
      line-height: 124%;
    }
  }

  .control-dots {
    margin-bottom: 40px !important;
  }

  .prev-arrow {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 40px;
    background: var(--Primary-Primary-3, #b6f2e6);
    backdrop-filter: blur(9.5px);
    cursor: pointer;
  }

  .next-arrow {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 40px;
    background: var(--Primary-Primary-3, #b6f2e6);
    backdrop-filter: blur(9.5px);
    cursor: pointer;
  }

  .mobile-indicators {
    @include d-flex(space-between);
    gap: 18px;
  }

  .activeBtn {
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.14);
    backdrop-filter: blur(9.5px);
    color: #fff;
    @include font-medium(1.4rem);
    padding: 8px 20px;
    line-height: normal;
    cursor: pointer;
  }

  .sliderButton {
    @include font-medium(1.4rem);
  }
}
