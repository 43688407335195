@import 'styles/variables.scss';

// Form Section

.form {
  background-color: #f6fdfc;
  padding: 64px 16px;

  &Heading {
    text-align: center;
    margin-bottom: 40px;

    h3 {
      @include font-bold($font-size: 4rem);
      line-height: 124%;
      color: $color-black-1;
      margin-bottom: 16px;
    }

    p {
      @include font-regular($font-size: 2rem);
      line-height: 150%;
      color: var(--gray-grey-1, #829c96);
      margin-bottom: 40px;
      max-width: 753px;
      margin: 0 auto;
    }
  }

  &Details {
    max-width: 1200px;

    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid $color-black-1;
      background-color: #f6fdfc;
      outline: none;

      &::placeholder {
        @include font-regular($font-size: 2rem);
        color: $color-green-3;
      }
    }
  }

  &Contents {
    display: flex;
    padding-bottom: 40px;
    gap: 20px;

    label {
      @include font-medium($font-size: 3rem);
      color: #0a3830;
      white-space: nowrap;
    }

    input {
      @include font-regular($font-size: 2rem);
      padding: 12px;
      color: $color-black-0;
      width: 100%;
      border: none;
      border-bottom: 1px solid $color-black-1;
      background-color: #f6fdfc;
      outline: none;

      &::placeholder {
        @include font-regular($font-size: 2rem);
      }
    }
  }

  &Groups {
    display: flex;
    padding-bottom: 40px;
    gap: 20px;
    flex-basis: 100%;

    label {
      @include font-medium($font-size: 3rem);
      color: #0a3830;
      white-space: nowrap;
    }

    input {
      @include font-regular($font-size: 2rem);
      padding: 12px;
      color: $color-black-3;
      width: 100%;
      border: none;
      border-bottom: 1px solid $color-black-1;
      background-color: #f6fdfc;
      outline: none;
      flex-grow: 1;

      &::placeholder {
        @include font-regular($font-size: 2rem);
        color: $color-green-3;
      }
    }
  }

  &List {
    display: flex;
    gap: 32px;
    padding-bottom: 40px;

    label {
      @include font-medium($font-size: 3rem);
      color: #0a3830;
      white-space: nowrap;
    }

    ul {
      list-style-type: none;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      li {
        display: flex;

        input[type='radio'] {
          display: none;
          width: initial;
          height: initial;
        }

        label {
          padding: 16px 24px;
          border-radius: 36px;
          border: 1px solid $color-green-3;
          @include font-regular($font-size: 2rem);
          line-height: 150%;
          color: $color-green-3;
          cursor: pointer;
        }

        input:checked + label {
          background: $color-green-0;
          color: $color-grey-4;
          padding: 16px 24px;
          border-radius: 36px;
        }
      }
    }
  }

  &Button {
    @include d-flex($jc: flex-end);

    button {
      padding: 12px 24px;
      @include font-medium($font-size: 1.6rem);
      color: $color-black-1;
      border-radius: 4px;
      line-height: 124%;
      cursor: pointer;
      background: $color-blue-2;
      border: 0;
      transition: all 0.2s ease;
      &:hover {
        background: transparent;
        color: $color-black-1;
        box-shadow: 0 0 0 1px inset $color-blue-2;
      }
    }
  }
}

// responsive
@media (max-width: 768px) {
  .form {
    padding: 40px 16px;

    &Heading {
      margin-bottom: 0;
      h3 {
        font-size: 2.4rem;
        padding-bottom: 8px;
        margin-bottom: 0;
      }
      p {
        font-size: 1.6rem;
        padding-bottom: 32px;
      }
    }

    &Contents {
      flex-wrap: wrap;
      gap: 8px;
      padding-bottom: 20px;

      label {
        font-size: 2rem;
      }

      input {
        padding: 8px 8px 8px 0px;
        font-size: 1.6rem;
        border-radius: 0;

        &::placeholder {
          font-size: 1.6rem;
        }
      }
    }

    &List {
      flex-wrap: wrap;
      gap: 8px;
      padding-bottom: 20px;

      label {
        @include font-medium($font-size: 2rem);
      }

      ul {
        gap: 16px;
        padding-left: 0;

        li:nth-child(-1n + 2) {
          width: 100%;
          label {
            flex-basis: 100%;
            text-align: center;
            padding: 14px 20px;
            font-size: 1.6rem;
          }

          input:checked + label {
            padding: 14px 20px;
          }
        }

        li:nth-child(4n + 3),
        li:nth-child(4n + 4) {
          width: 47%;
          flex-grow: 1;

          label {
            flex-basis: 100%;
            text-align: center;
            padding: 14px 20px;
            font-size: 1.6rem;
          }

          input:checked + label {
            padding: 14px 20px;
          }
        }

        li:nth-child(-5n + 5) {
          width: 100%;
          label {
            flex-basis: 100%;
            text-align: center;
            padding: 14px 20px;
            font-size: 1.6rem;
          }

          input:checked + label {
            padding: 14px 20px;
          }
        }

        li:nth-child(6) {
          width: 48%;
          label {
            flex-basis: 100%;
            text-align: center;
            padding: 14px 20px;
            font-size: 1.6rem;
          }

          input:checked + label {
            padding: 14px 20px;
          }
        }
      }
    }

    &Groups {
      flex-wrap: wrap;
      gap: 8px;
      padding-bottom: 8px;

      label {
        font-size: 2rem;
      }

      label:last-child {
        padding-top: 12px;
      }

      input {
        padding: 8px 8px 8px 0px;
        font-size: 1.6rem;
        border-radius: 0;

        &::placeholder {
          font-size: 1.6rem;
        }
      }
    }

    &Button {
      button {
        padding: 13px 10px;
        font-size: 1.4rem;
        width: 100%;
        margin-top: 12px;
      }
    }
  }
}
