@import 'styles/variables.scss';

.newsBlog {
  background: $color-grey-11;
  padding: 80px 0;

  &Container {
    @include d-flex($jc: flex-start);
    padding-top: 64px;
    align-items: stretch;
    column-gap: 30px;
    flex-wrap: wrap;
    row-gap: 32px;
  }

  h3 {
    @include font-bold($font-size: 4rem);
    line-height: 124%;
    color: $color-black-1;
    text-align: center;
  }

  &Section {
    border-radius: 10px;
    border: 1px solid $color-grey-17;
    background: $color-white-0;
    padding: 24px 24px 32px;

    @include d-flex($jc: flex-start);
    align-items: flex-start;
    flex-basis: 31.1%;
    flex-direction: column;

    &Img {
      width: 330px;
    }

    span {
      color: $color-green-1;
      @include font-regular($font-size: 1.4rem);
      padding: 32px 0 7px;
      display: block;
    }

    h4 {
      color: $color-black-5;
      line-height: 32px;
      @include font-semibold($font-size: 2.4rem);
    }

    p {
      color: $color-grey-16;
      line-height: 24px;
      @include font-regular($font-size: 1.6rem);
      padding: 8px 0 32px;
    }

    &Button {
      button {
        color: $color-black-1;
        line-height: 124%;
        @include font-medium($font-size: 1.6rem);
        padding: 12px 24px;
        background: $color-blue-2;
        border-radius: 6px;
        border: 0;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 992px) {
    padding: 40px 16px;

    &Container {
      @include d-flex($jc: center);
      padding-top: 24px;
      row-gap: 24px;
    }

    h3 {
      @include font-bold($font-size: 2.4rem);
    }

    &Section {
      padding: 16px 16px 24px;
      flex-basis: 100%;

      &Img {
        width: 100%;

        img {
          width: 100%;
        }
      }

      span {
        padding: 24px 0 7px;
      }
    }
  }
}
