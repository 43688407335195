@import 'styles/variables.scss';
@import url('https://api.fontshare.com/v2/css?f[]=satoshi@900,700,500,300,400&display=swap');
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  overscroll-behavior: none;
}

body {
  height: 100%;
  overflow: auto;
  overscroll-behavior: none;
}

html,
body {
  max-width: 100vw;
  font-size: 10px;
  font-family: 'Satoshi', sans-serif;
  -webkit-font-smoothing: antialiased;
}

img {
  max-width: 100%;
  height: fit-content;
}

a {
  color: inherit;
  text-decoration: none;
}

.commonHeading {
  margin-bottom: 32px;
  text-align: center;

  h1 {
    // color: $color-black-4;
    color: #14705f;
    @include font-medium(2rem);
    line-height: 150%;
    margin-bottom: 16px;
    @include d-flex(center);
    gap: 8px;
    text-transform: uppercase;
  }

  p {
    color: $color-black-1;
    @include font-bold(3.8rem);
    line-height: 124%;
  }
}

.btnShadow {
  border: 4px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0px 14px 20px 0px rgba(255, 255, 255, 0.07);
  width: fit-content;
  border-radius: 8px;
}

.commonButton {
  border-radius: 8px;
  background: #2cd9b8;
  padding: 14px 26px;
  color: #051a16;
  @include font-regular(1.8rem);
  line-height: normal;
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  box-shadow: 0px 0px 0 4px rgba(255, 255, 255, 0.07);

  label {
    cursor: pointer;
  }

  &:hover {
    color: rgb(49, 49, 49);

    &::after {
      clip-path: circle(100% at 50% 0%);
      border-radius: 8px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    clip-path: circle(0% at 50% 350%);
    z-index: 0;
    transition: all 0.8s ease;
  }

  .buttonText {
    position: relative;
    z-index: 2;
    transition: all 0.8s ease;
  }
}

section {
  width: 100%;
  padding: 80px 16px;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}

.swiperPagination {
  margin-top: 80px;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .swiper-pagination-bullet {
    background-color: $color-black-1;
    stroke-width: 1px;
    border: 1px solid $color-grey-0;
    width: 12px;
    height: 12px;
    opacity: 1;
    transition: all 300ms cubic-bezier(0.38, 0.39, 0.6, 0.59);
  }

  .swiper-pagination-bullet-active {
    transition: all 300ms ease-in-out;
    background-color: $color-grey-0;
    stroke-width: 1px;
    border: 1px solid $color-grey-0;
    width: 38px;
    height: 12px;
    opacity: 1;
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  section {
    padding: 40px 16px;
  }

  .commonHeading {
    h1 {
      @include font-regular(1.4rem);
      margin-bottom: 10px;
    }

    p {
      @include font-bold(2.4rem);
    }
  }
}
