@import 'styles/variables.scss';

.ourTechnology {
  width: 100%;
  background-color: $color-green-6;
  padding: 64px 120px;

  &Heading {
    margin: 0 auto;
    max-width: 943px;
    text-align: center;
    width: 100%;
    h4 {
      width: 100%;
      color: $color-grey-4;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      padding-bottom: 10px;
    }

    h3 {
      color: $color-grey-6;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
    }
  }

  &Content {
    display: grid;
    padding-top: 28px;
    grid-template-columns: repeat(2, 1fr);
    gap: 140px;
    width: 100%;

    .imageContainer {
      @include d-flex($jc: center);
      height: 100vh;
      position: sticky;
      top: 0px;
      flex-direction: column;
      .rotateBox {
        aspect-ratio: 1/1;
        position: relative;
        transition: transform 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
        overflow: hidden;
        flex-basis: 70%;

        .step-01,
        .step-02,
        .step-03 {
          overflow: hidden;
          width: 100%;
          height: 90%;
          border-radius: 8px;

          position: absolute;
          top: 0px;
          left: 0px;

          transition: opacity 200ms ease-in-out;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .textContainer {
      @include d-flex($jc: center);
      align-items: flex-start;
      flex-direction: column;
      color: var(--gray-grey-6, #e8e9e9);
      font-style: normal;
      .textItem {
        @include d-flex($jc: center);
        flex-direction: column;
        gap: 12px;
        min-height: 100vh;
        height: 100vh;
        h3 {
          font-size: 40px;
          font-weight: 700;
          line-height: 124%;
          padding-bottom: 24px;
          max-width: 484px;
        }
        p {
          font-size: 24px;
          font-weight: 500;
          line-height: 150%;
          color: $color-grey-1;
          max-width: 484px;
        }
      }

      .textImage {
        display: none;
      }
    }
  }
}

@media (max-width: 900px) {
  .ourTechnology {
    width: 100%;
    background-color: $color-green-6;
    padding: 40px 16px;

    &Heading {
      margin: 0 auto;
      max-width: 906px;
      text-align: center;
      width: 100%;
      h4 {
        font-size: 14px;
      }

      h3 {
        font-size: 24px;
      }
    }

    &Content {
      display: block;
      padding-top: 0px;

      .imageContainer {
        display: none;
      }

      .textContainer {
        .textItem {
          min-height: unset;
          height: unset;
          h3 {
            font-size: 20px;
            padding-bottom: 4px;
          }
          p {
            font-size: 16px;
          }
        }

        .textImage {
          display: block;
          overflow: hidden;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          aspect-ratio: 1/1;
          margin: 36px 0 16px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            aspect-ratio: 1/1;
          }
        }
      }
    }
  }
}
